import React from 'react'
import './thanks.css'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const getThanksImage = graphql`
  {
    fluid: file(relativePath: { eq: "thanks.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
    `

const Thanks = () => {
    const data = useStaticQuery(getThanksImage);
    return (
        <div className='thanks-wrap'>
            <div className="container">
                <div className="row">
                <div className="col-md-6 mx-auto py-5 thanks-adjust">
                <Img fluid={data.fluid.childImageSharp.fluid} />
                
                </div>

                
                </div>
                <div className="row">
                <h1 className="row-thanks mx-auto">THANKS !</h1>
                
                </div>
                <div className="row">
                <h3 className="row-thanks mx-auto">For Reaching Out To Us, we'll get back to you shortly.</h3>
                </div>
                
            </div>
        </div>
    )
}

export default Thanks
