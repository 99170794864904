import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Thanks from "../components/thanks/thanks"

const ThanksPage = () => (
  <Layout>
    <SEO title="Thanks For Choosing Techvizon" pathname="/thanks" description="Thanks for choosing techvizon for our services, we will get back to you shortly." />
    <Thanks/> 
  </Layout>
)

export default ThanksPage
